<template>
	<div class="page_box">
		<div class="top_box">
			<div class="top_title">人员查询</div>
			<div class="top_flex">
				<div class="search_flex">
					<div class="texts">部门岗位</div>
					<div class="inputs_box">
						<bumen_cascader ref="bumen_cascader" @confirm="confirm_bumen_cascader_value" />

						<!-- <el-cascader :options="options" :props="props" clearable></el-cascader> -->
					</div>
				</div>

				<div class="search_flex">
					<div class="texts">时间范围</div>
					<div class="inputs_box">
						<el-date-picker   v-model="date_select" :picker-options="pickerOptions"
							value-format="yyyy-MM-dd" type="date" placeholder="选择日期" clearable>
						</el-date-picker>

					</div>
				</div>
				<div class="search_flex">
					<div class="btn_ends" @click="handle_search()">搜索</div>
				</div>
			</div>

		</div>
		<div class="bom_box">
			<div class="bom_btn_flex">
				<div class="lefts">
					{{ page_title }}
				</div>
			</div>
			<div class="bom_box_list">
				<el-table :data="list" stripe style="width: 100%" header-cell-class-name="el_table_title"
					@selection-change="handle_list_change" v-loading="loading" height="570">

					<el-table-column fixed prop="_name" label="姓名" width=""></el-table-column>
					<el-table-column prop="id" label="基本信息" width="">
						<el-table-column prop="_bumen" label="部门" width="200"></el-table-column>
						<el-table-column prop="_position" label="岗位" width="200"></el-table-column>
					</el-table-column>
					<el-table-column prop="id" label="打卡信息" width="">
						<el-table-column prop="checkInTime" label="上班签到" width="200"></el-table-column>
						<el-table-column prop="checkOutTime" label="下班签到" width="200"></el-table-column>
						<el-table-column prop="checkInTime1" label="上班签到" width="200"></el-table-column>
						<el-table-column prop="checkOutTime1" label="下班签到" width="200"></el-table-column>
					</el-table-column>
					<el-table-column prop="id" label="下井信息" width="">
						<el-table-column prop="downCheckInTime" label="下井时间" width="200"></el-table-column>
						<el-table-column prop="downCheckOutTime" label="出井时间" width="200"></el-table-column>
					</el-table-column>
					<el-table-column prop="id" label="带班下井信息" width="">
						<el-table-column prop="downCheckInTime1" label="下井时间" width="200"></el-table-column>
						<el-table-column prop="downCheckOutTime1" label="出井时间" width="200"></el-table-column>
					</el-table-column>
				</el-table>
				<div class="pages">
					<page :pageson='count' @row="row" @currentPage="currentPage"></page>
				</div>
			</div>
		</div>


	</div>
</template>
<script>
	import dayjs from 'dayjs';
	import bumen_cascader from '@/components/bumen/bumen_cascader.vue'; //部门选择
	import page from "@/components/page/page.vue"; //分页  统一的
	export default {
		components: {
			bumen_cascader,
			page,
		},
		data() {
			return {
				// date_select: dayjs().format('YYYY-MM-DD'), //考勤日期
				date_select: '', //考勤日期
				bumen_position_info: {}, //
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() > Date.now();
					},
				},
				//所选的部门岗位信息

				loading: false, //加载总
				page_title: '', //页面名称  来之路由
				check_list: [], //选中的
				//提交数据
				forms: {
					keyword: '',
				},
				list: [

				],
				pages: {
					page: 1,
					limit: 10,
				},
				count: 0,

			};
		},
		computed: {},
		watch: {

		},

		created() {
			this.page_title = this.$route.meta.title
			this.get_list()
		},
		mounted() {

		},
		methods: {
			//接受条数
			currentPage(page) {
				console.log(page)
				this.pages.page = page
				this.get_list()
			},
			// 接收条数
			row(row) {
				console.log(row)
				this.pages.limit = row
				this.get_list()
			},
			//选择
			handle_list_change(e) {
				console.log('多选', e)
				this.check_list = e
			},
			//搜素
			handle_search() {
				this.pages.page = 1
				this.get_list()
			},
			//表单回调 触发重新渲染数据
			handle_get_list() {
				this.get_list()
			},
			//统一的列表接口
			get_list() {
				console.log(this.forms)
				this.loading = true
				this.$api("kaoqin/getDayList", {
					...this.pages,
					...this.bumen_position_info, //部门岗位id
					today: this.date_select, //日期
				}, "get").then((res) => {
					this.loading = false
					if (res.code == 200) {
						this.count = res.data.count
						let list = res.data.list;
						list.forEach(v => {
							let user = v.user
							v._name = user.name;
							v._bumen = this.mix_format_bumen_title(user.depart_title);
							// v._bumen = user.depart_title;
							v._position = user.level6;
						})

						this.list = list
					} else {
						alertErr(res.msg)
					}
				});
			},

			do_kaoqin_manager() {
				this.$router.push('/kaoqin_user_manager')
			},
			do_import_kaoqin() {},
			do_import_xiajin() {},


			confirm_bumen_cascader_value(value) {
				this.$log('勾选部门数据', value)
			let departIds = '';

				if (typeof value[0] == 'object') {

					let last_id_arr = value.map(arr => arr[arr.length - 1])

					departIds = last_id_arr.join(',')

				} else {

					departIds = value[value.length - 1];

				}

				let departItems = JSON.stringify(value)

				this.$log('departIds', departIds)
				this.$log('departItems', departItems)

				this.bumen_position_info = {
					deptId: departIds
				}
			}
		},



	};
</script>

<style scoped lang="less">
	.page_box {
		height: 100%;

		// background-color: red;
		.top_box {
			width: 100%;
			// height: 80px;
			background: #FFFFFF;
			border-radius: 8px 8px 8px 8px;
			padding-bottom: 29px;

			.top_title {
				padding: 10px 17px;
				font-family: Microsoft YaHei, Microsoft YaHei;
				font-weight: bold;
				font-size: 14px;
				color: #333333;
			}

			.top_flex {
				display: flex;
				align-items: center;
				// justify-content: space-between;
				flex-wrap: wrap;
				padding: 0 77px;

				.search_flex {
					// width: 25%;
					display: flex;
					align-items: center;
					padding-right: 48px;

					.texts {
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #333333;
						white-space: nowrap;
						padding-right: 12px;
					}

					.inputs_box {
						flex: 1;

						input {
							width: 236px;
							height: 40px;
							background: #FFFFFF;
							border-radius: 0px 0px 0px 0px;
							border: 1px solid #E8E8E8;
							padding: 0 9px;
							font-family: Microsoft YaHei, Microsoft YaHei;
							font-weight: 400;
							font-size: 14px;
							color: #747474;

						}
					}


					.btn_ends {
						width: 80px;
						height: 40px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						border-radius: 2px 2px 2px 2px;
						text-align: center;
						line-height: 40px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}




		}

		.bom_box {
			background: #FFFFFF;

			.bom_btn_flex {
				display: flex;
				align-items: center;
				justify-content: space-between;
				width: 100%;
				height: 51px;
				background: #FFFFFF;
				box-shadow: 0px 3px 8px 1px rgba(0, 0, 0, 0.06);
				border-radius: 4px 4px 4px 4px;
				margin-top: 20px;
				padding: 0 20px;

				.lefts {
					font-family: Microsoft YaHei, Microsoft YaHei;
					font-weight: bold;
					font-size: 14px;
					color: #333333;
				}

				.rights {
					display: flex;
					align-items: center;

					.btns {
						margin-left: 20px;
						background: linear-gradient(90deg, #157DE9 0%, #3697FD 100%);
						padding: 6px 13px;
						font-family: Microsoft YaHei, Microsoft YaHei;
						font-weight: 400;
						font-size: 14px;
						color: #FFFFFF;
						cursor: pointer;

						&:hover {
							background: linear-gradient(90deg, #0564ca 0%, #3697FD 100%);

						}
					}

				}
			}

			.bom_box_list {
				width: 1640px;
				height: 670px;
				padding: 20px 20px 32px 20px;

				.pages {
					padding-top: 30px;
				}
			}

		}
	}
</style>